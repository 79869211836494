:root {
    --bg-color: #0a1f1c;
    --text-color: #e0e0e0;
    --primary-color: #d5f365;
    --secondary-color: #094d19;
    --accent-color: #39db34;
    --dark-accent: rgb(0, 199, 36);
  }
  
  body {
    background-color: var(--bg-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
  }
  
  .number-deck-landing {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    line-height: 1.6;
  }
  
  header {
    text-align: center;
    margin-bottom: 3rem;
    position: relative;
  }
  
  nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(10, 31, 28, 0.9);
    padding: 1rem;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  nav a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  nav a:hover {
    color: var(--primary-color);
  }
  
  .header-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    color: var(--primary-color);
  }
  
  .tagline {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .badges {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }
  
  .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
    border: 0px solid black;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--bg-color);
  }
  
  .btn-secondary {
    background-color: var(--dark-accent);
    color: var(--text-color);
  }
  
  .btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  section {
    margin-bottom: 4rem;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--primary-color);
  }
  
  .feature-tabs {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .tab-buttons {
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .tab-buttons button {
    flex: 1;
    padding: 1rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    color: var(--text-color);
    transition: background-color 0.3s ease;
  }
  
  .tab-buttons button.active {
    background-color: var(--primary-color);
    color: var(--bg-color);
  }
  
  .tab-content {
    padding: 2rem;
  }
  
  .tab-content h3 {
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .tab-content ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .tab-content li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .tab-content li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--primary-color);
  }
  
  .installation-steps {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
  }
  
  .step {
    text-align: center;
    max-width: 200px;
  }
  
  .step-number {
    width: 40px;
    height: 40px;
    background-color: var(--primary-color);
    color: var(--bg-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
    font-weight: bold;
  }
  
  .tech-badges {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  footer {
    text-align: center;
    margin-top: 3rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }