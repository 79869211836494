/* Please ❤ this if you like it! */


@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

body{
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	color: #c4c3ca;
	background-color: #1f2829 !important;
	overflow-x: hidden;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
a {
	cursor: pointer;
}
a:hover {
	text-decoration: none;
}

/* ScrollBar */

/* width */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #757171; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #d5f365;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #b0ca55;
  }

/* Loading */
.loadingSection {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* Ensure it covers everything */
	flex-direction: column;
	font-family: monospace;
	font-size: 4.5em;
	font-weight: 900;
}

.loadingSection .progress-bar-container {
  width: 100%;
  position: relative;
  margin-top: 10px;
}

.loadingSection .progress-bar {
  position: absolute;
  height: 5px;
  background-color: #d5f365;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.1s ease-out;
}

.fade-in {
	animation: fadeInUp 1s ease-out forwards;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
  
/* #Cursor
================================================== */

.cursor,
.cursor2,
.cursor3{
	position: fixed;
	border-radius: 50%;	
	transform: translateX(-50%) translateY(-50%);
	pointer-events: none;
	left: -100px;
	top: 50%;
	mix-blend-mode: difference;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.cursor{
	background-color: #fff;
	height: 0;
	width: 0;
	z-index: 99999;
}
.cursor2,.cursor3{
	height: 36px;
	width: 36px;
	z-index:99998;
	-webkit-transition:all 0.3s ease-out;
	transition:all 0.3s ease-out
}
.cursor2.hover,
.cursor3.hover{
	-webkit-transform:scale(2) translateX(-25%) translateY(-25%);
	transform:scale(2) translateX(-25%) translateY(-25%);
	border:none
}
.cursor2{
	border: 2px solid #fff;
	box-shadow: 0 0 22px rgba(255, 255, 255, 0.6);
}
.cursor2.hover{
	background: rgba(255,255,255,1);
	box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1200px){
	.cursor,.cursor2,.cursor3{
		display: none
	}
}

/* #Primary style
================================================== */

.section {
    position: relative;
	width: 100%;
	display: block;
}
.over-hide{
	overflow: hidden;
}
.full-height {
	height: 100vh;
}

/* #Socials
================================================== */

.socials {
	display: flex;
	align-items: center;
    justify-content: center;
	margin-top: 10px;
}
.socials > a {
	font-size: 20px;
	padding:10px;
	cursor:pointer;
}


/* #Navigation
================================================== */
 
.cd-header{
    position: fixed;
	width:100%;
	z-index:100;
	background: #ffffff01;
    backdrop-filter: blur(19px);
	mix-blend-mode: difference;
} 
.header-wrapper{
    display: flex;
    justify-content: space-between;
	align-items: center;
    padding: 20px;
} 
.logo-wrap {
	display:block;
	cursor: pointer;
}
.logo-wrap a {
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	font-weight: 100;
	font-size: 20px;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #fff;
	transition : all 0.3s ease-out;
}
.logo-wrap a span{ 
	color: #88a967;
}
.logo-wrap a:hover {
	opacity: 0.9;
}
.nav-but-wrap{ 
	transition : all 0.3s ease-out;
}
.menu-icon {
	height: 30px;
	width: 30px;
	position: relative;
	z-index: 2;
	cursor: pointer;
	display: block;
}
.menu-icon__line {
	height: 2px;
	width: 30px;
	display: block;
	background-color: #fff;
	margin-bottom: 7px;
	cursor: pointer;
	-webkit-transition: background-color .5s ease, -webkit-transform .2s ease;
	transition: background-color .5s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, background-color .5s ease;
	transition: transform .2s ease, background-color .5s ease, -webkit-transform .2s ease;
}
.menu-icon__line-left {
	width: 16.5px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.menu-icon__line-right {
	width: 16.5px;
	float: right;
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	-ms-transition: all 200ms linear;
	transition: all 200ms linear;
}
.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
	width: 30px;
}
.nav {
	position: fixed;
	z-index: 98;
}
.nav:before, .nav:after {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(20, 21, 26,0.6);
	border-bottom-left-radius: 200%;
	z-index: -1;
	-webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
	transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
	transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
	transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
	-webkit-transform: translateX(100%) translateY(-100%);
          transform: translateX(100%) translateY(-100%);
}
.nav:after {
	background: rgba(9,9,12,1);
	-webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.nav:before {
	-webkit-transition-delay: .2s;
          transition-delay: .2s;
}
.nav__content {
	position: fixed;
	visibility: hidden;
	top: 50%;
	margin-top: 20px;
	-webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
	width: 100%;
	text-align: center;
}
.nav__list {
	position: relative;
	padding: 0;
	margin: 0;
	z-index: 2;
}
.nav__list-item {
	position: relative;
	display: block;
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
	opacity: 0;
	text-align: center;
	color: #fff;
	overflow: hidden; 
	font-family: 'Montserrat', sans-serif;
	font-size: 6vh;
	font-weight: 100;
	line-height: 1.15;
	letter-spacing: 3px;
	-webkit-transform: translate(100px, 0%);
          transform: translate(100px, 0%);
	-webkit-transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, transform .3s ease;
	transition: opacity .2s ease, transform .3s ease, -webkit-transform .3s ease;
	margin-top: 0;
	margin-bottom: 0;
}
.nav__list-item a{ 
	position: relative;
	text-decoration: none;
	color: rgba(255,255,255,0.6);
	overflow: hidden; 
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
	font-weight: 100;
	z-index: 2;
	display: inline-block;
	text-transform: uppercase;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list-item a:after{ 
	position: absolute;
	content: '';
	top: 50%;
	margin-top: -2px;
	left: 50%;
	width: 0;
	height: 0;
	opacity: 0;
	background-color: #88a967;
	z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list-item a:hover:after{ 
	height: 4px;
	opacity: 1;
	left: 0;
	width: 100%;
}
.nav__list-item a:hover{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav a{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav a:after{ 
	height: 4px;
	opacity: 1;
	left: 0;
	width: 100%;
}
body.nav-active .nav__content {
	visibility: visible;
}
body.nav-active .menu-icon__line {
	background-color: #fff;
	-webkit-transform: translate(0px, 0px) rotate(-45deg);
          transform: translate(0px, 0px) rotate(-45deg);
}
body.nav-active .menu-icon__line-left {
	width: 15px;
	-webkit-transform: translate(2px, 4px) rotate(45deg);
          transform: translate(2px, 4px) rotate(45deg);
}
body.nav-active .menu-icon__line-right {
	width: 15px;
	float: right;
	-webkit-transform: translate(-3px, -3.5px) rotate(45deg);
          transform: translate(-3px, -3.5px) rotate(45deg);
}
body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
	width: 15px;
}
body.nav-active .nav {
	visibility: visible;
}
body.nav-active .nav:before, body.nav-active .nav:after {
	-webkit-transform: translateX(0%) translateY(0%);
          transform: translateX(0%) translateY(0%);
	border-radius: 0;
}
body.nav-active .nav:after {
	-webkit-transition-delay: .1s;
          transition-delay: .1s;
}
body.nav-active .nav:before {
	-webkit-transition-delay: 0s;
          transition-delay: 0s;
}
body.nav-active .nav__list-item {
	opacity: 1;
	-webkit-transform: translateX(0%);
          transform: translateX(0%);
	-webkit-transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, transform .3s ease, color .3s ease;
	transition: opacity .3s ease, transform .3s ease, color .3s ease, -webkit-transform .3s ease;
}
body.nav-active .nav__list-item:nth-child(0) {
	-webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
body.nav-active .nav__list-item:nth-child(1) {
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
body.nav-active .nav__list-item:nth-child(2) {
	-webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
body.nav-active .nav__list-item:nth-child(3) {
	-webkit-transition-delay: 1s;
          transition-delay: 1s;
}
body.nav-active .nav__list-item:nth-child(4) {
	-webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
body.nav-active .nav__list-item:nth-child(5) {
	-webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
body.nav-active .nav__list-item:nth-child(6) {
	-webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
body.nav-active .nav__list-item:nth-child(7) {
	-webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
body.nav-active .nav__list-item:nth-child(8) {
	-webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
body.nav-active .nav__list-item:nth-child(9) {
	-webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
body.nav-active .nav__list-item:nth-child(10) {
	-webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}

.switch-wrap {
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 10;
    transform: translateY(-50%);
	width: 100%;
	-webkit-transition: all 500ms linear;
	transition: all 500ms linear; 
	margin: 0 auto;
	text-align: center;
}
.switch-wrap h1 {
	font-weight: 100;
	font-size: 46px;
	line-height: 1;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 40px;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
@media screen and (max-width: 580px){
  .switch-wrap h1 {
    font-size: 32px;
  }
}
.switch-wrap p {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 1;
	color: #88a967;
	text-align: center;
	margin-top: 15px;
}
.switch-wrap p span {
	position: relative;
}
.switch-wrap p span:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 2px;
	background-color: #fff;
	left: 0;
	bottom: -4px;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.switch-wrap p span:nth-child(2):before {
	opacity: 0;
}
#switch,
#circle {
	cursor: pointer;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
} 
#switch {
	width: 60px;
	height: 8px;
	margin: 0 auto;
	text-align: center;
	border: 2px solid #000;
	border-radius: 27px;
	background: #88a967;
	position: relative;
	display: inline-block;
}
#circle {
	position: absolute;
	top: -11px;
	left: -13px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
	background: #fff;
}
.switched {
	border-color: #88a967 !important;
	background: #000 !important;
}
.switched #circle {
	left: 43px;
	background: #000;
}

/* #Button
================================================== */

#bottone1 {
 padding-left: 33px;
 padding-right: 33px;
 padding-bottom: 16px;
 padding-top: 16px;
 border-radius: 9px;
 background: #d5f365;
 border: none;
 font-family: inherit;
 text-align: center;
 cursor: pointer;
 transition: 0.4s;
}

#bottone1:hover {
 box-shadow: 7px 5px 56px -14px #C3D900;
}

#bottone1:active {
 transform: scale(0.97);
 box-shadow: 7px 5px 56px -10px #C3D900;
}

#bottone1:focus{
    outline: none;
}

/* Footer
================================================== */

.footer {
	width: 100%;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 20px;
    font-weight: 100;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.3s ease-out;
}

.footer > div {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* #Light
================================================== */

body.light .cursor,
body.light .cursor2,
body.light .cursor3{
	mix-blend-mode: normal;
}
body.light .cursor2{
	border: 2px solid #1f2829;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
body.light .cursor2.hover{
	background: rgba(0,0,0,0.06);
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
	border-color: transparent;
}
body.light .logo-wrap a {
	color: #1f2829;
}
body.light .menu-icon__line {
	background-color: #1f2829;
}
body.light .nav:before {
	background: rgba(235, 235, 235,0.6);
}
body.light .nav:after {
	background: rgba(250,250,250,1);
}
body.light .nav__list-item a{ 
	color: rgba(0,0,0,0.6); 
}
body.light .nav__list-item a:hover{
	color: #1f2829;
}
body.light .nav__list-item.active-nav a{
	color: #1f2829;
}
body.light .switch-wrap h1 {
	color: #000;
}
body.light .switch-wrap p span:nth-child(2):before {
	opacity: 1;
	background-color: #000;
}
body.light .switch-wrap p span:nth-child(1):before {
	opacity: 0;
}

/* #Link to page
================================================== */

.link-to-portfolio {
	  position: fixed;
    bottom: 40px;
    right: 50px;
    z-index: 200;
    cursor: pointer;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 3px;
    background-position: center center;
    background-size: 65%;
  background-repeat: no-repeat;
    background-image: url('https://assets.codepen.io/1462889/fcy.png');
    box-shadow: 0 0 0 2px rgba(255,255,255,.1);
    transition: opacity .2s, border-radius .2s, box-shadow .2s;
    transition-timing-function: ease-out;
}
.link-to-portfolio:hover {
    opacity: 0.8;
    border-radius: 50%;
    box-shadow: 0 0 0 20px rgba(255,255,255,.1);
}

/* Portfolio */

.categories-tab {
    display: flex;
    margin-top: 100px;
    justify-content: center;
}

.category-container {
    padding: 5px 40px;
    background: #041603;
    border-radius: 30px;
}

@media (max-width:767px) {
	.category-container {
        display: flex;
        width: 90%;
        overflow-x: scroll;
	}
	.category-container::-webkit-scrollbar {
		display: none;
  	}
}

button.category-pills {
    background: #041603;
    border: 0;
    color: #23c01b;
    font-family: monospace;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

button.category-pills.active,
button.category-pills:hover {
    animation: pills 0.9s;
}

button.category-pills.active {
    background: #272828;
    color: white;
    border-radius: 10px;
    padding: 10px 50px;
}

button.category-pills:focus {
    outline: 0;
}

@keyframes pills {
    0%, 11.1%, 100% {
        transform: none;
    }
    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

.categories{
	margin: 20px 0px;
}

.category {
	background-color: darkolivegreen;
	color: white;
	padding: 5px 15px;
	margin: 0px 5px;
	border-radius: 20px;
	font-size: 11px;
	cursor: pointer;
}

.categories > .category:nth-child(1) {
	margin: 0 5px 0 0;
}

.portfolio-container {
	margin-top: 10px !important;
}

.card {
	font-family: monospace;
	margin-top: 40px;
	width: 350px;
	background: #292828da;
	border: 2px solid #000;
	border-radius: 15px;
	margin: 40px;
    opacity: 0;
    animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px;
}

.dots {
	display: flex;
	justify-content: last baseline;
}

.dot {
	width: 15px;
	height: 15px;
	border-radius: 50%;
}

.dot-1 {
	background-color: rgb(0, 132, 255);
}

.dot-2 {
	background-color: rgb(230, 0, 255);
}

.dot-3 {
	background-color: rgb(0, 255, 89);
}

.top p {
	font-size: 20px;
	font-weight: bold;
	}

.dot , .top p {
	margin: 5px;
}

.g-color {
	color: darkgreen;
}

.bottom {
	margin-top: 30px;
	padding: 0 25px;
}

.code {
	font-size: 15px;
}

.red {
	color: rgb(210, 63, 52);
}

.violet {
	color: rgb(181, 40, 203);
}

.blue {
	color: rgb(91, 131, 223);
}

.orange {
	color: rgb(195, 128, 3);
}

.white {
	color: rgb(163, 163, 163);
}

.space-4 {
	margin-left: 35px;
}

.space-4x2 {
	margin-left: 70px;
}

.sec-container{
	display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
	flex-wrap: wrap;
	margin-top: 100px;
}

@media (max-width: 768px) {
	.card { margin:20px }
	.contact { font-size: 25px!important; }
}

.bulb{
	transition: 0.3s;
	filter: brightness(0.5);
}
.bulb:hover{
	transition: 0.3s;
	filter: brightness(1.5);
}

/* Project */
.postHeader {
	display: flex;
	justify-content: center;
	align-items: center;
}

.postHeaderOverlay {
	background-color: rgba(0, 0, 0, 0.578);
	height: 300px;
	width: 100%;
	position: absolute;
	z-index: 1;
}

.postHeader h1 {
	position: absolute;
	z-index: 2;
	color: white;
	mix-blend-mode: difference;
	font-weight: 900;
	text-transform: capitalize;
	text-align: center;
}

.postHeader img {
	height: 300px;
	object-fit: cover;
}

.projectBody {
	padding: 10px 120px;
	margin-bottom: 30px;
}

@media (max-width:767px) {
	.projectBody{padding: 100px 10px;}
}

/* Snackbar
============================*/

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

/* OpenSource Page
======================*/

.opensource-featured-wrap {
	padding: 100px 10% 0 10%;
}

.opensource-featured-card {
	border: 1px solid #3d444d;
	padding: 20px;
	border-radius: 0.375rem;
	background-color: #0d1117;
}

.opensource-featured-card img {
	width: 100%;
	height: 220px;
	object-fit: cover;
}

.opensource-featured-card .content div {
	margin: 20px 0px;
	display: flex;
	align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}

.opensource-featured-card .content h3 {
	color: #4493f8;
	line-height: 0;
}

.chip {
	color: #9198a1;
	border: 1px solid #9198a1;
	border-radius: 30px;
	padding: 5px 10px;
	font-size: 8px;
}

.opensource-wrap {
	display: flex;
}

.opn-card {
	width: 48%;
	padding: 30px 30px 30px 30px;
}

.opensource-card {
	border: 1px solid #3d444d;
	border-radius: 0.375rem;
	background-color: #0d1117;
	padding: 20px;
}

.opensource-card  div {
	margin: 20px 0px;
	display: flex;
	align-items: center;
}

.opensource-card h4 {
	color: #4493f8;
	line-height: 0;
}

.code-circle {
	height: 15px;
	width: 15px;
	border-radius: 50%;
}

@media (max-width: 767px) {

	.opensource-featured-wrap {
		padding: 100px 5% 0 5%;
	}

	.opensource-wrap {
		display: block;
	}
	.opn-card {
		width: 100%;
		padding: 30px 5% 0 5%;
	}
}